'use client'

import React, { useCallback, useImperativeHandle, useRef } from 'react'
import { css, cx } from 'styled-system/css'
import { type IconVariantProps, icon } from 'styled-system/recipes'
import type { SystemStyleObject } from 'styled-system/types'

type IconProps = IconVariantProps & {
  css?: SystemStyleObject
  className?: string
  reactIcon: React.ReactNode
  size?: number
  onClick?: (e) => void
  pointer?: boolean
  disabled?: boolean
}

export const Icon = React.forwardRef<HTMLSpanElement, IconProps>((props: IconProps, forwardRef) => {
  const {
    reactIcon,
    className,
    css: cssProp = {},
    size = 24,
    onClick,
    pointer = false,
    disabled = false,
    ...rest
  } = props

  // Ref for the button
  const iconRef = useRef<HTMLSpanElement>(null)
  useImperativeHandle(forwardRef, () => iconRef.current as HTMLSpanElement)

  const cursor = disabled ? 'not-allowed' : typeof onClick === 'undefined' && !pointer ? 'default' : 'pointer'

  const handleClick = useCallback(
    e => {
      if (!disabled && onClick) onClick(e)
    },
    [onClick, disabled],
  )

  const rootClassName = cx(icon({ disabled }), css({ cursor }, cssProp), className)
  if (!reactIcon === null) return null
  const clonedIcon = React.cloneElement(reactIcon as React.ReactElement, {
    width: size,
    height: size,
  })
  return (
    <span {...rest} className={rootClassName} ref={iconRef} onClick={handleClick}>
      {clonedIcon}
    </span>
  )
})
Icon.displayName = 'Icon'
